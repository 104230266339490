<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'contratantes',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} ${loading ? '' : `(${data.length})`}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip v-if="login.permissions.insured_categories.create" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                color="success"
                :to="{ name: `${page_route}.store` }"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span v-text="'Crear'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
            dense
          >
            <template v-slot:item.index="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="primary"
                    :to="{
                      name: `${page_route}.view`,
                      params: { id: item.id },
                    }"
                  >
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      page_route: "insured_categories",
      login: this.$store.getters.getLogin,
      loading: true,
      data: [],
      search: "",
      headers: [
        {
          text: "#",
          filterable: false,
          value: "index",
          width: "50px",
        },
        {
          text: "Nombre",
          filterable: true,
          value: "name",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "50px",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleView(row_id) {
      this.$router.push({
        name: `${this.page_route}.view`,
        params: { id: row_id },
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/insureds/categories",
      headersToken(this.login.token)
    ).then((resp) => {
      this.data = resp.data.data;
      this.loading = false;
    });
  },
};
</script>