var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","plain":"","icon":"","to":{
                name: 'contratantes',
              }}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Átras')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(((_vm.$route.meta.title) + " " + (_vm.loading ? '' : ("(" + (_vm.data.length) + ")"))))}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[(_vm.login.permissions.insured_categories.create)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"success","to":{ name: (_vm.page_route + ".store") }}},on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,1548187040)},[_c('span',{domProps:{"textContent":_vm._s('Crear')}})]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('b',{domProps:{"textContent":_vm._s(index + 1)}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"primary","to":{
                    name: (_vm.page_route + ".view"),
                    params: { id: item.id },
                  }}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }